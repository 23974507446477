// composables/useFetch.js
import { ref } from 'vue';
import { ofetch } from 'ofetch';

export const useOFetch = async (method: string, url: string, body:any=null) :Promise<any> => {
  const config = useRuntimeConfig()
  const {$i18n}=useNuxtApp()
  const locale  = $i18n.locale.value

  const baseUrl = ref(config.public.MARKETPLACE_API_BASE_URL);
 
  const userStore = useUserStore()

  const { user } = storeToRefs(userStore)

  let defaultHeaders = {
    "Accept": "application/json",
    "Accept-Language":locale
  }

  let finalHeaders = {}

    if (user.value.access_token) {
      finalHeaders =  {
        ...defaultHeaders,
        "token": user.value.access_token,
      } 
    } else {
        finalHeaders =  {
          ...defaultHeaders
        }  
    }
  try {
    const res = await ofetch(baseUrl.value + '/' + url, {
      method: method,
      body: body,
      headers: finalHeaders,
      credentials: 'include',
    })
    return res;
  }
  catch (error) {
    throw error;
  }
}

